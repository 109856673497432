import React from 'react';

const ProjectImage = ({ imageClassName, ReactIcon }) => {
  return (
    <div className={`project-image ${imageClassName}`}>
      <div className="icon-wrapper">
        <ReactIcon className="icon" />
      </div>
    </div>
  );
};

export default ProjectImage;
