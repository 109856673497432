import React from 'react';
import { PiImageLight } from 'react-icons/pi';
import Item from './Item';

const Work = () => {
  return (
    <div id="work" className="site-portfolio">
      <div className="content-wrapper">
        <div className="portfolio-wrapper">
          <Item
            itemClassName="item-1"
            dateCategory="Jul - Sep 2022 / Website"
            title="Responsive Website Revamp for Legacy System"
            description="As part of a collaborative team, I led efforts to transform a legacy website into a fully responsive, accessible, and SEO-optimized platform. Our redesign introduced flexible content customization and adopted best web design practices, setting new standards for quality and usability across devices. This upgrade delivered a more user-friendly, modernized experience and allowed clients greater control over their content."
            imageClassName="img-1"
            ReactIcon={PiImageLight}
          />
          <Item
            itemClassName="item-2"
            dateCategory="Apr - Jun 2022 / Website"
            title="Custom Skew-Patterned Website with Carousel Integration"
            description="Collaborating with a talented team, I developed a uniquely styled website featuring custom skew patterns, an exclusive skew slider, and an integrated carousel slider. This project prioritized responsiveness and cross-browser compatibility to ensure a smooth, visually engaging experience across all devices."
            imageClassName="img-2"
            ReactIcon={PiImageLight}
          />
          <Item
            itemClassName="item-3"
            dateCategory="Jun - Jul 2020 / Website"
            title="End-to-End Development for Customizable Website"
            description="Taking full ownership of this project, I redeveloped an outdated site from scratch, resolving existing code and design issues. The revamped platform enhances user experience, allows full client customization, and offers versatile layout options-making it a preferred choice for clients seeking flexibility and design excellence."
            imageClassName="img-3"
            ReactIcon={PiImageLight}
          />
        </div>
      </div>
    </div>
  );
};

export default Work;
