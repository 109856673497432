import React from 'react';
import ProjectDetails from './ProjectDetails';
import ProjectImage from './ProjectImage';

const Item = ({
  itemClassName,
  dateCategory,
  title,
  description,
  imageClassName,
  ReactIcon,
}) => {
  return (
    <div className={`item ${itemClassName}`}>
      <ProjectDetails
        dateCategory={dateCategory}
        title={title}
        description={description}
      />
      <ProjectImage imageClassName={imageClassName} ReactIcon={ReactIcon} />
    </div>
  );
};

export default Item;
