import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Slider from './components/Slider';
import Work from './components/Work';
import Mentions from './components/Mentions';
import Footer from './components/Footer';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div id="main-wrapper" className="main-wrapper">
              <Header />
              <Slider />
              <Work />
              <Mentions />
              <Footer />
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
