import React from 'react';
import { Link } from 'react-router-dom';
import { AUTHOR_NAME } from '../constants';

const Logo = () => {
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="logo-container">
      <Link
        to="/"
        className="logo-link"
        title="Home"
        aria-label="Mayuresh Daphane's logo"
        onClick={handleLogoClick}
      >
        <span className="logo-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
          >
            <rect
              width="40"
              height="40"
              fill="rgba(208, 216, 228, 0.2)"
              rx="5"
            />
            <text
              x="50%"
              y="50%"
              fill="#8632f0"
              textAnchor="middle"
              transform="translate(0, 6)"
            >
              &lt;/&gt;
            </text>
          </svg>
        </span>
        <span className="logo-text">{AUTHOR_NAME}</span>
      </Link>
    </div>
  );
};

export default Logo;
